import reportWebVitals from "./reportWebVitals";

import { HashRouter } from "react-router-dom";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    supportedLngs: ["it", "en"],
    resources: {
      en: {
        translation: {
          prezzi: "Prices",
          orari: "When",
          telefono: "Telephone",
          email: "Email",
          pec: "PEC",
          sito: "Website",
          impostazioni: "Settings",
          lingua: "Language",
          aroundme: "Around Me",
          scoprimazara: "Discover Mazara",
          tutte: "All",
          dovedormire: "Where to sleep",
          dovemangiare: "Where to eat",
          museiecultura: "Museums and culture",
          itinerarituristici: "Tourist itineraries",
          saporieshopping: "Flavors and shopping",
          news: "News",
          rassegna: "Review",
          eventi: "Event",
          puntidiinteresse: "Points of interest",
          cerca: "Search",
          selezionaunacategoria: "Select a category",
          filtrapercategoria: "Filter",
          chiudi: "Close",
          filtra: "Filter",
          cambiolingua1: "Changing the language will restart the app",
          cambiolingua2: "Are you sure you want to change the language?",
          cambiolingua3: "Confirm",
          cambiolingua4: "Cancel",
          contattaci: "Contact us",
          testocontattaci1:
            "Mazara Valley is a project conceived by the Municipality of Mazara del Vallo in synergy with the tourist operators of the city.",
          testocontattaci2: "Call & Go",
          testocontattaci3: "Call us",
          testocontattaci4:
            "If you want to contact us by email you can do so at",
          testocontattaci5: "The Municipality of Mazara del Vallo",
          crediti: "Credits",
          crediti1: "Software design and implementation: ",
          vedieventi: "See the events of the review",
          vediluoghi: "See the places of the itinerary",
          portami: "Take me there",
          calendario: "Calendar",
          evidenza: "Highlights",
          nessunrisultatotrovatoattornoate: "No results found around you",
          vaiallascheda: "Go to the card",
          mostratutti: "Show all",
          nessunrisultatotrovato: "No results found",
          noncisonoeventiperilgiornoselezionato:
            "There are no events for the selected day",
          stabilimentibalneari: "Beach establishments",
          mesi: "Months",
          filtraperdata: "Filter by date",
          datainizio: "Start date",
          datafine: "End date",
          applicafiltro: "Apply filter",
          Gennaio: "January",
          Febbraio: "February",
          Marzo: "March",
          Aprile: "April",
          Maggio: "May",
          Giugno: "June",
          Luglio: "July",
          Agosto: "August",
          Settembre: "September",
          Ottobre: "October",
          Novembre: "November",
          Dicembre: "December",
          lunedi: "Monday",
          martedi: "Tuesday",
          mercoledi: "Wednesday",
          giovedi: "Thursday",
          venerdi: "Friday",
          sabato: "Saturday",
          domenica: "Sunday",
          lunedip: "Mon",
          martedip: "Tue",
          mercoledip: "Wed",
          giovedip: "Thu",
          venerdip: "Fri",
          sabatop: "Sat",
          domenicap: "Sun",
          inserisciilcodicedellacard: "Enter the code of the card",
          cartanonvalida: "Invalid card",
          lacartaegiaattivasuunaltrotelefonononpuoiattivarlasuquestotelefono:
            "The card is already active on another phone, you cannot activate it on this phone",
          erroresconosciutodurantelaverificadellacarta:
            "Unknown error during card verification",
          erroredurantelaverificadellacarta: "Error during card verification",
          aggiungiunaltracard: "Add another card",
          acquista: "Buy",
          aggiungilatuacfanovisitcard: "Add your Fano Visit Card",
          attivalafanovisitcard: "Activate the Fano Visit Card",
          inserisciilcodicechehairicevuto: "Enter the code you received",
          scannerizzailqrcode: "Scan the QR code",
          scansiona: "Scan",
          invia: "Send",
          errorenellachiamataapi: "Error in the API call",
          rispostaapinonvalida: "Invalid API response",
          erroredurantelachiamataallapi: "Error during the call to the API",
          cardattivataconsuccesso: "Card activated successfully",
          erroredurantelattivazionedellacardriprovapiutardi:
            "Error during card activation, try again later",
          modifica: "Edit",
          inserisciidatiperlafanovisitcard:
            "Enter the data for the Fano Visit Card",
          nome: "Name",
          cognome: "Surname",
          provenienza: "City of origin",
          ladatanonpuoessereinferiorealgiornocorrente:
            "The date cannot be earlier than the current day",
          seriale: "Progessive number (Numero progressivo)",
          informativabreve: "Short information",
          reset: "Reset",
          visualizzaletuecard: "View your cards",
          inserisciilcodice: "Enter the code",
          oggi: "Today",
          siattiveratra: "It will be activated on",
          giorni: "days",
          seisicurodivolereliminarequestacarta:
            "Are you sure you want to delete this card?",
          attivo: "Active",
          nonattivo: "Not active",
          fanovisitcard: "Fano Visit Card",
          elimina: "Delete",
          serviziinclusicart: "Services included",
          giornirimanenti: "Remaining days",
          letuefanovisitcard: "Your Fano Visit Card",
          scadetra: "It will expire on:",
          giorniscade: "Days",
          scopridipiu: "Discover more",
          acquistafanocard: "Buy your Fano Card",
          sbloccaContenuto:
            "Unlock the content by activating or purchasing your Fano Visit Card",
          durata: "Duration",
          sceglilatipologia: "Choose the type",
          nessunrisultatocorrispondente: "No results matching your search",
          chiudicamera: "Close camera",
          viniecantine: "Wine and wineries",
        },
      },
      it: {
        translation: {
          prezzi: "Prezzi",
          orari: "Orari",
          telefono: "Telefono",
          email: "Email",
          pec: "PEC",
          sito: "Sito Web",
          impostazioni: "Impostazioni",
          lingua: "Lingua",
          evidenza: "Evidenza",
          aroundme: "Attorno a me",
          scoprimazara: "Scopri Mazara",
          tutte: "Tutte",
          dovedormire: "Dove dormire",
          dovemangiare: "Dove mangiare",
          museiecultura: "Musei e cultura",
          itinerarituristici: "Itinerari turistici",
          saporieshopping: "Sapori e shopping",
          news: "News",
          rassegna: "Rassegna",
          eventi: "Eventi",
          puntidiinteresse: "Punti di interesse",
          cerca: "Cerca",
          selezionaunacategoria: "Seleziona una categoria",
          filtrapercategoria: "Filtra per categoria",
          chiudi: "Chiudi",
          filtra: "Filtra",
          cambiolingua1: "Cambiando la lingua l'app verrá riavviata",
          cambiolingua2: "Sei sicuro di voler cambiare la lingua?",
          cambiolingua3: "Conferma",
          cambiolingua4: "Annulla",
          contattaci: "Contattaci",
          testocontattaci1:
            "Mazara Valley è un progetto ideato dal Comune di Mazara del Vallo in sinergia con gli operatori turistici della città.",
          testocontattaci2: "Call & Go",
          testocontattaci3: "Chiamaci",
          testocontattaci4:
            "Se vuoi contattarci via mail puoi farlo all'indirizzo",
          testocontattaci5: "Il Comune di Mazara del Vallo",
          crediti: "Crediti",
          crediti1: "Progettazione e realizzazione software: ",
          vedieventi: "Vedi gli eventi della rassegna",
          vediluoghi: "Vedi i luoghi dell'itinerario",
          portami: "Portami",
          calendario: "Calendario",
          nessunrisultatotrovatoattornoate:
            "Nessun risultato trovato attorno a te",
          vaiallascheda: "Vai alla scheda",
          mostratutti: "Mostra tutti",
          nessunrisultatotrovato: "Nessun risultato trovato",
          noncisonoeventiperilgiornoselezionato:
            "Non ci sono eventi per il giorno selezionato",
          stabilimentibalneari: "Stabilimenti balneari",
          mesi: "Mesi",
          filtraperdata: "Filtra per data",
          datainizio: "Data inizio",
          datafine: "Data fine",
          applicafiltro: "Applica filtro",
          Gennaio: "Gennaio",
          Febbraio: "Febbraio",
          Marzo: "Marzo",
          Aprile: "Aprile",
          Maggio: "Maggio",
          Giugno: "Giugno",
          Luglio: "Luglio",
          Agosto: "Agosto",
          Settembre: "Settembre",
          Ottobre: "Ottobre",
          Novembre: "Novembre",
          Dicembre: "Dicembre",
          lunedi: "Lunedí",
          martedi: "Martedí",
          mercoledi: "Mercoledí",
          giovedi: "Giovedí",
          venerdi: "Venerdí",
          sabato: "Sabato",
          domenica: "Domenica",
          lunedip: "Lun",
          martedip: "Mar",
          mercoledip: "Mer",
          giovedip: "Gio",
          venerdip: "Ven",
          sabatop: "Sab",
          domenicap: "Dom",
          inserisciilcodicedellacard: "Inserisci il codice della card",
          cartanonvalida: "Carta non valida",
          lacartaegiaattivasuunaltrotelefonononpuoiattivarlasuquestotelefono:
            "La carta è già attiva su un altro telefono, non puoi attivarla su questo telefono",
          erroresconosciutodurantelaverificadellacarta:
            "Errore sconosciuto durante la verifica della carta",
          erroredurantelaverificadellacarta:
            "Errore durante la verifica della carta",
          aggiungiunaltracard: "Aggiungi un'altra card",
          acquista: "Acquista",
          aggiungilatuacfanovisitcard: "Aggiungi la tua Fano Visit Card",
          attivalafanovisitcard: "Attiva la Fano Visit Card",
          inserisciilcodicechehairicevuto:
            "Inserisci il codice di attivazione che hai ricevuto",
          scannerizzailqrcode: "Scannerizza il QR Code",
          scansiona: "Scansiona",
          invia: "Invia",
          errorenellachiamataapi: "Errore nella chiamata api",
          rispostaapinonvalida: "Risposta api non valida",
          erroredurantelachiamataallapi: "Errore durante la chiamata all'api",
          cardattivataconsuccesso: "Card attivata con successo",
          erroredurantelattivazionedellacardriprovapiutardi:
            "Errore durante l'attivazione della card, riprova più tardi",
          modifica: "Modifica",
          inserisciidatiperlafanovisitcard:
            "Inserisci i dati per la Fano Visit Card",
          nome: "Nome",
          cognome: "Cognome",
          provenienza: "Provenienza",
          ladatanonpuoessereinferiorealgiornocorrente:
            "La data non può essere inferiore al giorno corrente",
          seriale: "Numero progressivo",
          informativabreve: "Informativa breve",
          reset: "Reset",
          visualizzaletuecard: "Visualizza le tue card",
          inserisciilcodice: "Inserisci il codice di attivazione",
          oggi: "Oggi",
          siattiveratra: "Si attiverà tra",
          giorni: "giorni",
          seisicurodivolereliminarequestacarta:
            "Sei sicuro di voler eliminare questa carta?",
          attivo: "Attivo",
          nonattivo: "Non attivo",
          fanovisitcard: "Fano Visit Card",
          elimina: "Elimina",
          serviziinclusicart: "Servizi inclusi",
          giornirimanenti: "Giorni rimanenti",
          letuefanovisitcard: "Le tue Fano Visit Card",
          scadetra: "Scade tra:",
          giorniscade: "Giorni",
          scopridipiu: "Scopri di più",
          acquistafanocard: "Acquista la tua Fano Card",
          sbloccaContenuto:
            "Sblocca il contenuto attivando o acquistando la tua Fano Visit Card",
          durata: "Durata",
          sceglilatipologia: "Scegli la tipologia",
          nessunrisultatocorrispondente:
            "Nessun risultato corrispondente alla tua ricerca",
          chiudicamera: "Chiudi camera",
          viniecantine: "Vini e cantine",
        },
      },
    },
    fallbackLng: "it",
    detection: {
      order: ["localStorage", "path", "cookie", "htmlTag", "path", "subdomain"],
      caches: ["localStorage", "cookie"],
    },
  });

const renderReactDom = () => {
  ReactDOM.render(
    <HashRouter>
      <App />
    </HashRouter>,
    document.getElementById("root")
  );
};

// renderReactDom()

if (window.cordova) {
  document.addEventListener(
    "deviceready",
    () => {
      renderReactDom();
      localStorage.setItem("uuid", device.uuid);
      if ("geolocation" in navigator) {
        navigator.permissions
          .query({ name: "geolocation" })
          .then(function (result) {
            if (result.state === "granted") {
              console.log("Geolocation permission granted");
            } else if (result.state === "prompt") {
              navigator.geolocation.getCurrentPosition(
                function () {
                  console.log("Geolocation permission granted");
                },
                function () {
                  console.log("Geolocation permission denied");
                }
              );
            } else {
              console.log("Geolocation permission denied");
            }
          });
      } else {
        console.log("Geolocation is not supported by this browser");
      }
    },
    false
  );
} else {
  renderReactDom();

  if ("geolocation" in navigator) {
    navigator.permissions
      .query({ name: "geolocation" })
      .then(function (result) {
        if (result.state === "granted") {
          console.log("Geolocation permission granted");
        } else if (result.state === "prompt") {
          navigator.geolocation.getCurrentPosition(
            function () {
              console.log("Geolocation permission granted");
            },
            function () {
              console.log("Geolocation permission denied");
            }
          );
        } else {
          console.log("Geolocation permission denied");
        }
      });
  } else {
    console.log("Geolocation is not supported by this browser");
  }
}
//
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DateFilter } from './DateFilter';
import { MonthDayFilter } from './MonthFilter';
import { ShowAllButton } from './ShowAllButton';
import { EventList } from './EventList';
import { BackButton } from '../../components/UI/BackButton';
import { Link } from 'react-router-dom';
import list from '../../assets/icons/list.png';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { BarLoader } from 'react-spinners';
import { css } from '@emotion/react';
import { url } from '../../url';

const EventiTest = () => {
  const { t } = useTranslation();
  const currentMonth = new Date().getMonth();
  const currentDay = new Date().getDate();
  const [loading, setLoading] = useState(true);

  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedDay, setSelectedDay] = useState(currentDay);

  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM-DD'));
  /*  const [isLoadingNextDayEvents, setIsLoadingNextDayEvents] = useState(false);
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0); */

  const currentLanguage = localStorage.getItem('i18nextLng');

  // set current date if selected day or selected month change

  useEffect(() => {
    const fetchEvents = async () => {
      const response = await axios.get(`${url}/${currentLanguage}/api/eventi`);
      const events = response.data.rows.filter((event) => {
        if (!event.data_fine) {
          return true;
        }
        return new Date(event.data_fine) >= new Date();
      });
      if (events.length > 0) {
        setLoading(false);
      }
      setEvents(events);
    };
    fetchEvents();
  }, []);

  useEffect(() => {
    if (events?.length > 0) {
      window.scrollTo(0, 0);

      const filterEvents = events.filter((event) => {
        // current date must be between start and end date with moment
        if (!parseInt(event.attiva_data_singola)) {
          const eventStartDate = moment(event.data_inizio).format('YYYY-MM-DD');
          const eventEndDate = moment(event.data_fine).format('YYYY-MM-DD');
          return moment(currentDate).isBetween(eventStartDate, eventEndDate) || moment(currentDate).isSame(eventStartDate) || moment(currentDate).isSame(eventEndDate);
        } else {
          return event.data_singola.some((data) => {
            const formattedData = moment(data).format('YYYY-MM-DD');

            return currentDate === formattedData;
          });
        }
      });

      const sortedEvents = filterEvents.sort((a, b) => {
        const aDate = new Date(a.data_inizio);
        const bDate = new Date(b.data_inizio);
        return bDate - aDate;
      });
      setFilteredEvents(sortedEvents);
    }
  }, [currentDate, events]);

  useEffect(() => {
    if (filteredEvents.length === 0) {
      setNoResults(true);
    } else {
      setNoResults(false);
    }
  }, [filteredEvents]);

  /*  const loadNextDayEvents = () => {
    const nextDay = moment(currentDate).add(1, "days").format("YYYY-MM-DD");
    setSelectedDay(parseInt(moment(currentDate).add(1, "days").format("DD")));

    setIsLoadingNextDayEvents(true);
    setTimeout(() => {
      setCurrentDate(nextDay);
      setIsLoadingNextDayEvents(false);
    }, 200);
  };
 */
  /*   const handleScroll = (event) => {
    const { scrollTop } = event.target;

    if (scrollTop > lastScrollTop) {
      // L'utente sta scorrendo verso il basso
      setIsScrollingDown(true);
    } else {
      // L'utente sta scorrendo verso l'alto
      setIsScrollingDown(false);
    }

    if (isScrollingDown && !isLoadingNextDayEvents) {
      const { clientHeight, scrollHeight } = event.target;
      if (scrollTop + clientHeight >= scrollHeight - 100) {
        loadNextDayEvents();
      }
    }

    // Memorizza la posizione dello scroll per la prossima iterazione
    setLastScrollTop(scrollTop);
  }; */
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  return (
    <section>
      <div className={'flex flex-wrap h-[16vh] content-center relative z-0 p-5 bg-fanoBlue'}>
        <div className={'flex flex-wrap items-center relative w-full'}>
          <BackButton sfondo={'false'} />
          <span className={'text-white font-semibold text-[20px] ml-4'}>{t('calendario')}</span>
          <Link to={'/calendario'} className={'absolute right-0'}>
            <img src={list} />
          </Link>
        </div>
      </div>
      <div className={'flex flex-wrap'}>
        <div className='flex  py-5 px-3 h-[55vh] w-[70px]'>
          {/* Left panel with day buttons */}
          <div className='flex flex-wrap h-full w-[70px] pb-5'>
            <MonthDayFilter events={events} setFilteredEvents={setFilteredEvents} selectedDay={selectedDay} setSelectedDay={setSelectedDay} selectedMonth={selectedMonth} setSelectedMonth={setSelectedMonth} currentDate={currentDate} setCurrentDate={setCurrentDate} />
            <ShowAllButton setFilteredEvents={setFilteredEvents} events={events} />
            <DateFilter events={events} setFilteredEvents={setFilteredEvents} />
          </div>
        </div>

        <div className='flex  py-5 px-3 h-[80vh]' style={{ width: 'calc(100% - 70px)' }}>
          <div
            className='flex-grow h-full overflow-auto'
            /* onScroll={handleScroll} */
          >
            <div className='px-4'>
              {loading ? (
                <div className='flex justify-center items-center h-[60vh]'>
                  <BarLoader color={'#2b6cb0'} css={override} size={50} />
                </div>
              ) : (
                <div className='event-column'>{noResults ? <p>{t('noncisonoeventiperilgiornoselezionato')}</p> : <EventList events={filteredEvents} currentDate={currentDate} />}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EventiTest;

import { BackButton } from "../components/UI/BackButton";
import React from "react";
import territorio from "../assets/territorio1.jpg";
export const ComuneETerritorio = () => {
  return (
    <section className="bg-fanoBlue text-left w-full">
      <div
        className={"flex flex-wrap h-[16vh] content-center relative z-10 p-5"}
      >
        <div className={"flex flex-wrap items-center"}>
          <BackButton sfondo={"false"} />
          <span className={"text-white font-semibold text-[18px] ml-4"}>
            Mazara tra barocco, storia e tradizioni
          </span>
        </div>
      </div>
      <img src={territorio} className={"mb-4"} />
      <p className={"text-white font-bold text-[20px] mx-6 my-2"}>
        Benvenuti nella valle dei sapori, dei colori e della storia.
      </p>
      <p className={"text-white font-semibold text-[16px] mx-6"}>
        Una terra da scoprire e da vivere intensamente. Arte e cultura si
        mescolano ai profumi e all'intensità del mare blu, alla natura
        incontaminata e ai sapori unici della tavola. La città presenta nel suo
        perimetro storico numerosi esempi di architettura sacra di impianto
        barocco e arabo-normanno. Si tratta di una valle autentica e genuina
        come la gente che la abita e che vi aspetta con calore e accoglienza.
      </p>
      <p className={"text-white font-bold text-[20px] mx-6 my-2"}>
        Mazara Valley, terra autentica.
      </p>
      <p className={"text-white font-semibold text-[16px] mx-6 mb-6"}>
        Un tempo denominata "la città delle 100 Chiese", Mazara del Vallo è
        anche sede di uno dei vescovadi più antichi di Sicilia. Stupende Chiese
        piene di tesori artistici da scoprire e con una lunga storia da
        raccontare. Laghi naturali e zone umide alternate ad aree boschive e di
        macchia mediterranea, oasi verdi abitate da una ornitofauna stanziale e
        migratoria ideale per la pratica del birdwatching e degli sport a
        contatto con la natura. Il paesaggio collinare degrada verso la costa
        bagnata dalle acque cristalline e incontaminate del Mediterraneo. Un
        vero paradiso per chi pratica gli sport d'acqua, ama la natura e
        l'escursionismo. Interessanti e suggestivi anche i percorsi da
        attraversare sulle due ruote.
      </p>
    </section>
  );
};
